import React from "react";
import Gallery from "react-photo-gallery";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import BlogTemplateStyles from "./blogTemplate.module.scss";

export const query = graphql `
    query($slug: String!) {
        contentfulBlogPosts(slug: {eq:$slug}) {
            headerImage {
                file {
                    url
                    fileName
                }
            }
            categories {
                name
            }
            title
            date(formatString:"MMMM DD, YYYY")
            body {
                raw
            }
            bodyImages {
                file {
                    url
                    fileName
                }
                gatsbyImageData
            }
        }
    }
    `


const Blog = (props) => {

    const photos = props.data.contentfulBlogPosts.bodyImages.map((bodyImage) => {
        
        return {
            src: bodyImage.file.url,
            width: bodyImage.gatsbyImageData.width,
            height: bodyImage.gatsbyImageData.height
        }
    })      

    return (
        <Layout>
            <div className={BlogTemplateStyles.blogPost}>
                <div className={BlogTemplateStyles.image} style={{ backgroundImage: `url(${props.data.contentfulBlogPosts.headerImage.file.url})` }}></div>
                <div className={BlogTemplateStyles.postContainer}>
                    <div>
                        <p className={BlogTemplateStyles.category}>{props.data.contentfulBlogPosts.categories[0].name}</p>
                    </div>
                    <h1 className={BlogTemplateStyles.title}>{props.data.contentfulBlogPosts.title}</h1>
                    <div className={BlogTemplateStyles.dateContainer}>
                        <span className={BlogTemplateStyles.dateContainer}>{props.data.contentfulBlogPosts.date}</span>
                    </div>
                    <div className={BlogTemplateStyles.socialContainer}>
                        <div class="s9-widget-wrapper"></div>
                    </div>
                    <div>
                        <p>{renderRichText(props.data.contentfulBlogPosts.body)}</p>
                        <div>
                        <Gallery photos={photos} />
                            {/* {props.data.contentfulBlogPosts.bodyImages.map((bodyImage) => {
                                return (
                                    <img src={bodyImage.file.url} alt={bodyImage.file.fileName} />
                                )
                            })} */}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Blog;